.button {
    background-color: #10BED2;
    box-shadow: 0 0px 14px rgba(53, 64, 82, 0.05), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 7px 14px;
}
.button:hover {
    background-color: #0eabbd;
}
.button-label {
    color: #fff;
}
.link-text {
    color: #10BED2;
}
.s10 {
    padding-top: 10px;
}
.s20 {
    padding-top: 20px;
}
.s30 {
    padding-top: 30px;
}
.s40 {
    padding-top: 40px;
}
.s50 {
    padding-top: 50px;
}

.auth-padding {
    padding-left: 210px;
    padding-right: 210px;
}
